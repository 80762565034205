import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import PickListService from '../../../../services/PickListService';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import ActionEditorBase from '../ActionEditorBase';
import { createPickListSource } from '../PickListSource';
import { useCurrentClient } from '../../../../global-state/Clients';

//                _   _               _
//      /\       | | (_)             (_)
//     /  \   ___| |_ _  ___  _ __    _ ___
//    / /\ \ / __| __| |/ _ \| '_ \  | / __|
//   / ____ \ (__| |_| | (_) | | | | | \__ \
//  /_/___ \_\___|\__|_|\___/|_| |_| |_|___/          _
//  |  __ \                              | |         | |
//  | |  | | ___ _ __  _ __ ___  ___ __ _| |_ ___  __| |
//  | |  | |/ _ \ '_ \| '__/ _ \/ __/ _` | __/ _ \/ _` |
//  | |__| |  __/ |_) | | |  __/ (_| (_| | ||  __/ (_| |
//  |_____/ \___| .__/|_|  \___|\___\__,_|\__\___|\__,_|
//              | |
//              |_|

const MultiSelectListEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, patchData, form, invalidStates } = props;
  const { sourceType } = action?.data || {};

  const { t } = useTranslation('organisation');

  const [picklistSources, setPicklistSources] = useState<GroupBase<Item>[]>([]);

  const client = useCurrentClient((x) => x.value);

  useEffect(() => {
    PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id }).then((res) => {
      setPicklistSources(createPickListSource(res.data, t));
    });
  }, [client?.id, t]);

  const onSourceTypeChanged = (option: Item) => {
    patchData({ sourceType: option.id });
  };

  const picklistSourceText = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType)?.text;

  return (
    <div data-cy="multi-select-list-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      {sourceType && <div className="text-dpm-12 mt-4">Picklist source</div>}
      <DropdownSelect
        data-cy="source"
        className={`${!sourceType && 'mt-8'}`}
        placeholder="Picklist Source"
        value={{ id: sourceType, text: picklistSourceText || '', value: sourceType }}
        options={picklistSources}
        onChange={onSourceTypeChanged}
      />

      <ConditionsProperties form={form} action={action} patchAction={patchAction} invalidStates={invalidStates} />

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} />
    </div>
  );
};

export default MultiSelectListEditor;
